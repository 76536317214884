<template>
  <el-dialog title="" :visible="dialogVisible" @close="closeDialog" class="city-dialog" :close-on-click-modal="is_province" :close-on-press-escape="is_province" :show-close="is_province" :before-close="close_province">
    <h3 class="current-city"><i class="el-icon-location-information"></i>{{province_shortname || '未选择省份'}}</h3>
    <p class="title">选择省份</p>
    <div class="tag-list">
      <span v-for="item in cityList">
        <el-tag class="tag-item" @click="check_province(item)" v-if="item.status == 'normal'">{{item.first}}-{{item.shortname}}</el-tag>
        <el-tag class="tag-item" type="info" v-else>{{item.first}}-{{item.shortname}}</el-tag>
      </span>
    </div>

  </el-dialog>
</template>

<script>
import Cookies from 'js-cookie'
import { province_list } from "@/api/index.js";//引入
import { changeprovince } from "@/api/user.js";//引入
export default {
  data(){
    return{
      is_province:false,
      province_id:null,
      province_name:null,
      province_shortname:null,
      dialogVisible: true,
      cityList:[]
    }
  },
  created() {
    //判断是否选择了省份
    if (Cookies.get('province_id')) {
      this.is_province = true;
      this.province_id =  Cookies.get('province_id');
      this.province_name =  Cookies.get('province_name');
      this.province_shortname =  Cookies.get('province_shortname');
    }
    this.loadData();
  },
  methods:{
    closeDialog(){
      this.$emit('cancel');
    },
    close_province(done) {
      if(this.is_province) {
        done();
      }
      console.log('未选择省份不可关闭')
    },
    async check_province(item) {
      let province_id = item.id;
      let province_name = item.name;
      let province_shortname = item.shortname;

      const loading = this.$loading({
        lock: true,
        text: '切换中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      await changeprovince({
        province_id:province_id
      }).then(res => {
          loading.close();
          if(res.code == 0){
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
              });
              return;
          }
          Cookies.set('province_id', province_id)
          Cookies.set('province_name', province_name)
          Cookies.set('province_shortname', province_shortname)
          location.reload()
      }).catch(err=>{
          loading.close();
          this.$message({
            showClose: true,
            message: '系统繁忙,请稍候再试',
            type: 'error'
          });
          console.log(err);
      })
      // this.is_province = true;
      // this.province_id = province_id;
      // this.province_name = province_name;
      // this.$emit('cancel');
    },
    async loadData() {
				await province_list().then(res => {
          if(res.code == 1) {
            this.cityList = res.data;
          }
				}).catch(err=>{
					console.log(err);
				})
    }
  }
}

</script>

<style scoped lang="less">
.current-city{
  text-align: center;font-size: 22px;line-height: 2;color:#000;
  i{
    margin-right: 6px;
  }
}
.title{
  text-align: center;font-size: 16px;color:#999;line-height: 2.5;
}
.tag-list{
  padding:5px 0 0;
  .tag-item{
    margin:7px;font-size: 18px;cursor: pointer;padding:0;width: 18%;text-align: center;line-height: 2;height: auto;
  }
}
</style>