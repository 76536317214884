import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import { getToken } from '@/utils/auth'

export default function (config) {
    // 创建axios实例
    const service = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        // baseURL: process.env.VUE_APP_BASE_API,
        // baseURL: 'https://admindev.xbzhiyuan.com/api',
        baseURL: 'https://admindev.xbzhiyuan.com/api',
        // 超时 b
        timeout: 50000
    })

    // request拦截器
    service.interceptors.request.use(
        config => {
            // Do something before request is sentconfig.headers['Content-Type'] = 'application/json';
            getToken() && (config.headers['token'] = getToken())
            config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json'
            // 8080
            if (config.type == 'file') {
                config.headers['content-type'] = 'application/multipart/form-data'
            } else if (config.type == 'form') {
                config.headers['Content-type'] = 'application/x-www-form-urlencoded'
            }
            if (config.method.toLowerCase() === 'get') {
                config.data = true
            }
            return config
        },
        error => {
            console.log(error)
            Promise.reject(error)
        }
    )

    // 响应拦截器
    service.interceptors.response.use(res => {
        const code = res.data.code
        if (code === 401) {
            MessageBox.confirm(
                '登录状态已过期，您可以继续留在该页面，或者重新登录',
                '系统提示', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }
            ).then(() => {
                console.log('登录状态已过期')
            })
        } else if (code !== 1) {
            // Message({
            //     message: res.data.msg,
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            return res.data
        } else {
            return res.data
        }
    },
    error => {
        if(error.response.data.code == 401) {
            MessageBox.confirm( 
                '登录状态已过期，您可以继续留在该页面，或者重新登录',
                '系统提示', {
                confirmButtonText: '重新登录',
                showCancelButton:false,
                type: 'warning'
            }
            ).then(() => {
                window.location.href="/login"
            });
        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(error)
        }
    }
    )

    return service(config)
}


// export default service
