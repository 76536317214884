import { render, staticRenderFns } from "./cityChooseModal.vue?vue&type=template&id=bfbc1bd4&scoped=true"
import script from "./cityChooseModal.vue?vue&type=script&lang=js"
export * from "./cityChooseModal.vue?vue&type=script&lang=js"
import style0 from "./cityChooseModal.vue?vue&type=style&index=0&id=bfbc1bd4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfbc1bd4",
  null
  
)

export default component.exports