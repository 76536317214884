<template>
  <div>
    <!-- 网站头部 -->
    <headerBarUser></headerBarUser>
    <div class="main">
      <div class="inner">
        <sideNav></sideNav>
        <div class="main-content">
          <router-view/>
        </div>
      </div>
  
    </div>
  
  </div>
  </template>
  
  <script>
  import headerBarUser from '@/components/headerBarUser.vue'
  import sideNav from '@/components/sideNav.vue'
  
  export default {
    name: 'BasicLayout',
    components:{
      headerBarUser,sideNav,
    }
  }
  </script>
  
  <style scoped lang="less">
  .main{
    background: url("@/assets/img/background.png") no-repeat;padding:20px 0 15px;overflow: auto;
    height: calc(100vh - 90px);box-sizing: border-box;
    &>.inner{
      display: flex;width: 1200px;box-sizing: border-box;height:100%;min-height: 685px;
      background: #EFF4F7 100%;
      border-radius: 58px 25px 25px 25px;
      box-shadow: 0px 10px 28px 8px rgba(182,213,215,0.67);
      border:10px solid #fff;overflow: auto;
      .main-content{
        width:calc(100% - 130px);margin:10px 0 0 10px;overflow: auto;height:98%;
      }
    }
  }
  
  </style>