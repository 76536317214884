<template>
  <div class="footer-bar">
    <div class="inner">
      <div class="left">
        <h3>关于我们</h3>
        <p v-for="item in aboutList">{{ item.title }}：{{ item.content }}</p>
      </div>
      <div class="middle">
        <h3>产品服务</h3>
        <p v-for="item in productList" @click="goto_url(item.url)"><img :src="item.image" alt="">{{ item.title }}</p>
      </div>
      <div class="right">
        <h3>关于我们</h3>
        <ul>
          <li v-for="item in followList">
            <img :src="item.image" alt="">
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="copy-right">
      版权所有：{{ beian }}
    </div>
  </div>
</template>

<script>
import { footer } from "@/api/index.js";//引入
import Cookies from 'js-cookie'

export default {
  name: 'Footer',
  data(){
    return{
      aboutList:[],
      productList:[],
      followList:[],
      beian:null,
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    // 工具栏的请求
    async loadData()  {
      let province_id =  Cookies.get('province_id') ?? 0;
      let obj = {
        province_id : province_id
      }
      await footer(obj).then(res => {
        console.log(res,'resreszererere')
        if(res.code == 1) {
          let resData = res.data;
          this.aboutList = resData.about;
          this.productList = resData.product;
          this.followList = resData.follow;
          this.beian = resData.beian;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    goto_url(url) {
      if(url) {
        window.open(url)
      }
    }
  }

}
</script>

<style scoped lang="less">
.footer-bar{
  background: #0A9A78;color:#fff;
  .inner{
    display: flex;
    justify-content: space-between;
    padding: 40px 0 20px;
    .left, .middle, .right{
      h3{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      p{
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    .left{
      width: 30%;
    }
    .middle{
      width: 30%;text-align: center;
      p{
        img{
          height:32px;vertical-align: middle;margin-right:10px;
        }
      }
    }
    .right{
      width: 30%;text-align: center;
      ul{
        display: flex;
        justify-content: space-around;
        li{
          img{
            width: 100px;
            height: 100px;
          }
          p{
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}
.copy-right{
  text-align: center;
  padding: 10px 0;
}

</style>