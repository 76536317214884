<template>
    <div class="side-nav">
      <ul class="list">
        <li class="item">
          <router-link to="userInfo" :class="currentRoute(['userInfo'])">
            <i class="el-icon-user-solid"></i>
            <p>用户资料</p>
          </router-link>
        </li>
        <li class="item">
          <router-link to="myRecommend" :class="currentRoute(['myRecommend'])">
            <i class="el-icon-user-solid"></i>
            <p>推荐奖励</p>
          </router-link>
        </li>
        <li class="item">
          <router-link to="mycardList" :class="currentRoute(['mycardList'])">
            <i class="el-icon-user-solid"></i>
            <p>志愿卡管理</p>
          </router-link>
        </li>
        <li class="item">
          <router-link to="accountMoney" :class="currentRoute(['accountMoney','accountScore','upgradeVip','rechargeScore'])">
            <i class="el-icon-user-solid"></i>
            <p>财务管理</p>
          </router-link>
        </li>
        <li class="item">
          <router-link to="noticeList" :class="currentRoute(['noticeList','noticeDetail'])">
            <i class="el-icon-user-solid"></i>
            <p>系统消息</p>
          </router-link>
        </li>
        <li class="item">
          <router-link to="myAppointment" :class="currentRoute(['myAppointment'])">
            <i class="el-icon-user-solid"></i>
            <p>我的预约</p>
          </router-link>
        </li>
        <li class="item">
          <router-link to="wishPlan" :class="currentRoute(['wishPlan'])">
            <i class="el-icon-user-solid"></i>
            <p>志愿方案</p>
          </router-link>
        </li>
        <li class="item">
          <router-link to="plannerCenter" :class="currentRoute(['plannerCenter','editPlanner','plannerProject','plannerOrder','plannerOverOrder','plannerOrderInfo','plannerRate'])">
            <i class="el-icon-user-solid"></i>
            <p>规划中心</p>
          </router-link>
        </li>
      </ul>
    
    
    </div>
    </template>
    
    <script>
    export default {
      computed: {
      },
      data() {
        return {
          active:1,
          activeMenu:['/userInfo','','','/accountScore','/noticeList','/myAppointment','','/plannerCenter']
        }
      },
      created() {
      },
      methods: {
        currentRoute(menu) {
          let route = this.$route.path.replace('/', '');
          if(menu.includes(route)) {
            return 'active';
          }
        }
      }
    }
    </script>
    
    <style scoped lang="less">
    .side-nav{
      width:110px;text-align: center;box-sizing: border-box;padding:4px 8px;background: #1F7C69;
      border-radius: 58px 25px 25px 25px;
      .list{
        display: flex;flex-direction: column;justify-content: space-around;height: 100%;
        .item{
          a{
            color:#fff;padding:20px 0;border-radius: 40px;display: block;
            transition: all .5s;
            &.active{
              color:#F7B441;background: #fff;
              transform: scale(1.05);
              i{
                color:#F7B441;
              }
            }
          }
        }
      }
    }
    </style>