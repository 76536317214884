import request from "@/utils/request";//记得引入进来
 
export function index(params) {
    return request({
        method: 'post',
        url: '/index/index',
        data: params //可以简写为data
    })
}

export function footer(params) {
    return request({
        method: 'post',
        url: '/index/footer',
        data: params //可以简写为data
    })
}

export function header(params) {
    return request({
        method: 'post',
        url: '/index/header',
        data: params //可以简写为data
    })
}

export function province_list() {
    return request({
        method: 'get',
        url: '/index/province_list'
    })
}

export function school_list(params) {
    return request({
        method: 'post',
        url: '/index/school_list',
        data: params //可以简写为data
    })
}

export function school_detail(params) {
    return request({
        method: 'post',
        url: '/index/school_detail',
        data: params //可以简写为data
    })
}

export function major_list(params) {
    return request({
        method: 'post',
        url: '/index/major_list',
        data: params //可以简写为data
    })
}

export function major_list_all(params) {
    return request({
        method: 'post',
        url: '/index/major_list_all',
        data: params //可以简写为data
    })
}

export function major_title_all(params) {
    return request({
        method: 'post',
        url: '/index/major_title_all',
        data: params //可以简写为data
    })
}

export function major_detail(params) {
    return request({
        method: 'post',
        url: '/index/major_detail',
        data: params //可以简写为data
    })
}

export function params_list() {
    return request({
        method: 'get',
        url: '/index/params_list'
    })
}

export function area_list() {
    return request({
        method: 'get',
        url: '/index/area_list'
    })
}

export function planner_params() {
    return request({
        method: 'get',
        url: '/index/planner_params'
    })
}

export function planner_list(params) {
    return request({
        method: 'post',
        url: '/index/planner_list',
        data: params //可以简写为data
    })
}

export function planner_detail(params) {
    return request({
        method: 'post',
        url: '/index/planner_detail',
        data: params //可以简写为data
    })
}

export function recommend_planner_list(params) {
    return request({
        method: 'post',
        url: '/index/recommend_planner_list',
        data: params //可以简写为data
    })
}

export function planner_company_list(params) {
    return request({
        method: 'post',
        url: '/index/planner_company_list',
        data: params //可以简写为data
    })
}

export function planner_company_detail(params) {
    return request({
        method: 'post',
        url: '/index/planner_company_detail',
        data: params //可以简写为data
    })
}

export function recommend_planner_company_list(params) {
    return request({
        method: 'post',
        url: '/index/recommend_planner_company_list',
        data: params //可以简写为data
    })
}

export function planner_goods_list(params) {
    return request({
        method: 'post',
        url: '/index/planner_goods_list',
        data: params //可以简写为data
    })
}

export function planner_rate_list(params) {
    return request({
        method: 'post',
        url: '/index/planner_rate_list',
        data: params //可以简写为data
    })
}

export function level_list() {
    return request({
        method: 'get',
        url: '/index/level_list',
    })
}

export function price_list() {
    return request({
        method: 'get',
        url: '/index/price_list',
    })
}

export function circle_table_list(params) {
    return request({
        method: 'post',
        url: '/index/circle_table_list',
        data: params //可以简写为data
    })
}

export function circle_detail(params) {
    return request({
        method: 'post',
        url: '/index/circle_detail',
        data: params //可以简写为data
    })
}

export function focus_table_list(params) {
    return request({
        method: 'post',
        url: '/index/focus_table_list',
        data: params //可以简写为data
    })
}

export function focus_detail(params) {
    return request({
        method: 'post',
        url: '/index/focus_detail',
        data: params //可以简写为data
    })
}

export function helpc_list(params) {
    return request({
        method: 'post',
        url: '/index/helpc_list',
        data: params //可以简写为data
    })
}

export function assist_table_list(params) {
    return request({
        method: 'post',
        url: '/index/assist_table_list',
        data: params //可以简写为data
    })
}

export function assist_detail(params) {
    return request({
        method: 'post',
        url: '/index/assist_detail',
        data: params //可以简写为data
    })
}

export function nowwish_table_list(params) {
    return request({
        method: 'post',
        url: '/index/nowwish_table_list',
        data: params //可以简写为data
    })
}

export function wishtype_info(params) {
    return request({
        method: 'post',
        url: '/index/wishtype_info',
        data: params //可以简写为data
    })
}

export function wishtype_list(params) {
    return request({
        method: 'post',
        url: '/index/wishtype_list',
        data: params //可以简写为data
    })
}

