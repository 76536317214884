import Vue from 'vue'
import VueRouter from 'vue-router'
import basicLayout from "@/layouts/basicLayout.vue";
import basicLayoutUser from "@/layouts/basicLayoutUser.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: basicLayout,
    redirect:'/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
      },
      { // 规划师列表
        path: '/plannerList',
        name: 'plannerList',
        component: () => import('@/views/plannerList/index.vue'),
      },
      { // 规划师详情
        path: '/plannerInfo',
        name: 'plannerInfo',
        component: () => import('@/views/plannerInfo/index.vue'),
      },
      { // 规划机构列表
        path: '/companyList',
        name: 'companyList',
        component: () => import('@/views/companyList/index.vue'),
      },
      { // 规划机构详情
        path: '/companyInfo',
        name: 'companyInfo',
        component: () => import('@/views/companyInfo/index.vue'),
      },
      { // 大学列表  了解大学
        path: '/schoolList',
        name: 'schoolList',
        component: () => import('@/views/schoolList/index.vue'),
      },
      { // 专业列表  了解专业
        path: '/professionalList',
        name: 'professionalList',
        component: () => import('@/views/professionalList/index.vue'),
      },
      { // 大学详情
        path: '/schoolInfo',
        name: 'schoolInfo',
        component: () => import('@/views/schoolInfo/index.vue'),
      },
      { // 专业详情
        path: '/professionalInfo',
        name: 'professionalInfo',
        component: () => import('@/views/professionalInfo/index.vue'),
      },
      { // 客服帮助
        path: '/customerService',
        name: 'customerService',
        component: () => import('@/views/customerService/index.vue'),
      },
      { // 资讯列表
        path: '/circleList',
        name: 'circleList',
        component: () => import('@/views/circleList/index.vue'),
      },
      { // 资讯详情
        path: '/circleDetail',
        name: 'circleDetail',
        component: () => import('@/views/circleList/detail.vue'),
      },
      { // 焦点新闻列表
        path: '/newList',
        name: 'newList',
        component: () => import('@/views/newList/index.vue'),
      },
      { // 焦点新闻详情
        path: '/newDetail',
        name: 'newDetail',
        component: () => import('@/views/newList/detail.vue'),
      },
      { // 常见问题列表
        path: '/helpList',
        name: 'helpList',
        component: () => import('@/views/helpList/index.vue'),
      },
      { // 常见问题详情
        path: '/helpDetail',
        name: 'helpDetail',
        component: () => import('@/views/helpList/detail.vue'),
      },
      { // 常见问题详情
        path: '/volunteerList',
        name: 'volunteerList',
        component: () => import('@/views/volunteer/list.vue'),
      },
      { // 志愿方案详情
        path: '/wishPlanInfo',
        name: 'wishPlanInfo',
        component: () => import('@/views/wishPlan/detail.vue'),
      },
      { // 志愿方案详情
        path: '/wishPlanAdd',
        name: 'wishPlanAdd',
        component: () => import('@/views/wishPlan/add.vue'),
      },
    ]
  },
  {
    name: 'userPage',
    path: 'userPage',
    component: basicLayoutUser,
    children: [
      { // 用户中心-用户资料
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('@/views/userInfo/index.vue'),
      },
      { // 用户中心-改绑手机
        path: '/resetphone',
        name: 'resetphone',
        component: () => import('@/views/resetphone/index.vue'),
      },
      { // 用户中心-编辑资料
        path: '/editUser',
        name: 'editUser',
        component: () => import('@/views/editUser/index.vue'),
      },
      { // 志愿卡管理
        path: '/mycardList',
        name: 'mycardList',
        component: () => import('@/views/mycard/list.vue'),
      },
      { // 志愿卡管理
        path: '/myRecommend',
        name: 'myRecommend',
        component: () => import('@/views/myRecommend/index.vue'),
      },
      { // 充值VIP
        path: '/upgradeVip',
        name: 'upgradeVip',
        component: () => import('@/views/finance/vip.vue'),
      },
      { // 充值刷新点
        path: '/rechargeScore',
        name: 'rechargeScore',
        component: () => import('@/views/finance/rscore.vue'),
      },
      { // 刷新点账户
        path: '/accountScore',
        name: 'accountScore',
        component: () => import('@/views/finance/account.vue'),
      },
      { // 余额账户
        path: '/accountMoney',
        name: 'accountMoney',
        component: () => import('@/views/finance/money.vue'),
      },
      { // 我的预约
        path: '/myAppointment',
        name: 'myAppointment',
        component: () => import('@/views/myAppointment/index.vue'),
      },
      { // 用户中心-系统消息
        path: '/noticeList',
        name: 'noticeList',
        component: () => import('@/views/noticeList/index.vue'),
      },
      { // 用户中心-系统消息-详情
        path: '/noticeDetail',
        name: 'noticeDetail',
        component: () => import('@/views/noticeList/detail.vue'),
      },
      { // 志愿方案
        path: '/wishPlan',
        name: 'wishPlan',
        component: () => import('@/views/wishPlan/index.vue'),
      },
      { // 规划中心-规划师资料
        path: '/plannerCenter',
        name: 'plannerCenter',
        component: () => import('@/views/plannerCenter/index.vue'),
      },
      { // 规划中心-收费项目
        path: '/plannerProject',
        name: 'plannerProject',
        component: () => import('@/views/planner/project.vue'),
      },
      { // 规划中心-预约订单
        path: '/plannerOrder',
        name: 'plannerOrder',
        component: () => import('@/views/planner/order.vue'),
      },
      { // 规划中心-已完成订单
        path: '/plannerOverOrder',
        name: 'plannerOverOrder',
        component: () => import('@/views/planner/over_order.vue'),
      },
      { // 规划中心-预约订单
        path: '/plannerOrderInfo',
        name: 'plannerOrderInfo',
        component: () => import('@/views/planner/order_info.vue'),
      },
      { // 规划中心-考生评价
        path: '/plannerRate',
        name: 'plannerRate',
        component: () => import('@/views/planner/order_rate.vue'),
      },
      { // 规划中心-选择规划身份
        path: '/checkPlanner',
        name: 'checkPlanner',
        component: () => import('@/views/checkPlanner/index.vue'),
      },
      { // 规划中心-申请规划师1
        path: '/applyPlanner1',
        name: 'applyPlanner1',
        component: () => import('@/views/applyPlanner/index1.vue'),
      },
      { // 规划中心-申请规划师2
        path: '/applyPlanner2',
        name: 'applyPlanner2',
        component: () => import('@/views/applyPlanner/index2.vue'),
      },
      { // 规划中心-申请规划师3
        path: '/applyPlanner3',
        name: 'applyPlanner3',
        component: () => import('@/views/applyPlanner/index3.vue'),
      },
      { // 规划中心-申请规划机构1
        path: '/applyPlannerCompany1',
        name: 'applyPlannerCompany1',
        component: () => import('@/views/applyPlannerCompany/index1.vue'),
      },
      { // 规划中心-申请规划机构2
        path: '/applyPlannerCompany2',
        name: 'applyPlannerCompany2',
        component: () => import('@/views/applyPlannerCompany/index2.vue'),
      },
      { // 规划中心-申请规划机构3
        path: '/applyPlannerCompany3',
        name: 'applyPlannerCompany3',
        component: () => import('@/views/applyPlannerCompany/index3.vue'),
      },
      { // 规划中心-编辑规划师资料
        path: '/editPlanner',
        name: 'editPlanner',
        component: () => import('@/views/editPlanner/planner.vue'),
      },
      { // 规划中心-编辑规划机构资料
        path: '/editPlannerCompany',
        name: 'editPlannerCompany',
        component: () => import('@/views/editPlanner/company.vue'),
      },
      { // 规划中心-注销身份
        path: '/logoutPlanner',
        name: 'logoutPlanner',
        component: () => import('@/views/logoutPlanner/index.vue'),
      },
    ]
  },

  {
    path: '/test1',
    name: 'test1',
    component: () => import('@/views/test1/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/resetpass',
    name: 'resetpass',
    component: () => import('@/views/resetpass/index.vue'),
  },
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
