<template>
<div>
  <!-- 网站头部 -->
  <headerBar></headerBar>
  <router-view/>
  <!-- 网站底部 -->
  <footerBar></footerBar>
</div>
</template>

<script>
import headerBar from '@/components/headerBar.vue'
import footerBar from '@/components/footerBar.vue'
export default {
  name: 'BasicLayout',
  components:{
    headerBar,
    footerBar,
  }
}
</script>

<style scoped>

</style>